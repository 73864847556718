const slideUpShowAnimation = (delay = 0, y = 20) => {
  return {
    hidden: {
      y: y,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: delay,
      },
    },
  };
};

const sectionAnimation = {
  hidden: {
    scale: [1.0],
    opacity: 0,
    y: -20,
    z: 0,
  },
  visible: {
    y: [80, 0],
    scale: [1.0, 1.03, 1.0],
    opacity: 1,
    transition: {
      ease: "easeInOut",
    },
  },
};

const arrowAnimation = {
  hidden: {
    pathLength: 0,
  },
  visible: {
    pathLength: 1,
  },
};

const fadeIn = {
	hidden: { opacity: 0 },
	visible: { opacity: 1 },
};

export { slideUpShowAnimation, sectionAnimation, arrowAnimation, fadeIn };
