import classes from "./TestimonialsSlider.module.scss";
import { useState, useEffect, useRef } from "react";
import Image from "next/image";

// swiper stuff
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade } from "swiper";

import sliderImg1 from "../../public/images/testimonials-olek@2x.png";
import sliderImg1sm from "../../public/images/testimonials-olek-sm.png";
import sliderImg2 from "../../public/images/testimonials-aga@2x.png";
import sliderImg2sm from "../../public/images/testimonials-aga-sm.png";
import sliderImg3 from "../../public/images/testimonials-marcel@2x.png";
import sliderImg3sm from "../../public/images/testimonials-marcel-sm.png";
import sliderImg4 from "../../public/images/testimonials-pawel@2x.png";
import sliderImg4sm from "../../public/images/testimonials-pawel-sm.png";
import sliderImg5 from "../../public/images/testimonials-pedro@2x.png";
import sliderImg5sm from "../../public/images/testimonials-pedro-sm.png";
import sliderImg6 from "../../public/images/testimonials-krzysiek@2x.png";
import sliderImg6sm from "../../public/images/testimonials-krzysiek-sm.png";
import sliderImg7 from "../../public/images/testimonials-beata-b@2x.png";
import sliderImg7sm from "../../public/images/testimonials-beata-b-sm.png";
import sliderImg8 from "../../public/images/testimonials-beata-f@2x.png";
import sliderImg8sm from "../../public/images/testimonials-beata-f-sm.png";

// swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

const testimonialsData1 = [
  // {
  //   img: sliderImg3,
  //   imgWidth: 576,
  //   imgHeight: 733,
  //   imgSm: sliderImg3sm,
  //   offset: -10,
  //   text: "The visible results – that’s what motivates me at my work. It’s great to see our clients benefiting almost immediately from the effects of my work. At Commerce Media Tech we deliver new features very quickly, sometimes more than once a day!",
  //   author: {
  //     name: "Marcel",
  //     position: "Tech Lead, Zeropark",
  //   }
  // },
  {
    img: sliderImg7,
    imgWidth: 692,
    imgHeight: 733,
    imgSm: sliderImg7sm,
    offset: 86,
    text: "My top 3 things about the company: Truly committed & super-helpful colleagues, an incredible office, and the ability to have a real impact on our products. It was my second week at Commerce Media Tech when the changes I introduced were already used by our clients!",
    author: {
      name: "Beata",
      position: "Software Engineering Expert",
    }
  },
  {
    img: sliderImg5,
    imgWidth: 576,
    imgHeight: 733,
    imgSm: sliderImg5sm,
    offset: -35,
    text: "We are very open to talking and sharing our skills & knowledge across the company. Even if you don’t work with someone directly, it’s so natural to just approach them and ask. It helps a lot to get a basic understanding of what someone else is doing and how it translates to your task.",
    author: {
      name: "Pedro",
      position: "Tech Lead, Infrastructure",
    }
  },
  {
    img: sliderImg2,
    imgWidth: 576,
    imgHeight: 733,
    imgSm: sliderImg2sm,
    offset: 30,
    text: "I’m loud. I’ve got weird hair. Nobody cares. As a bit of an outsider, I’ve never felt more at home than at Commerce Media Tech. Here, the only thing that matters is the outcome of my work. And the great time spent with my colleagues!",
    author: {
      name: "Agnieszka",
      position: "Video Content Manager",
    },
  },
];

const testimonialsData2 = [
  {
    img: sliderImg4,
    imgWidth: 576,
    imgHeight: 733,
    imgSm: sliderImg4sm,
    offset: -20,
    text: "We’re not being told how to do our job. It’s for us to figure out. We don’t have software architects who tell us what technologies we should use. We don’t need special permissions to try something new. We’ve got great liberty of using whatever the AWS Cloud has to offer.",
    author: {
      name: "Paweł",
      position: "Tech Lead, Voluum DB",
    }
  },
  {
    img: sliderImg8,
    imgWidth: 692,
    imgHeight: 733,
    imgSm: sliderImg8sm,
    offset: 86,
    text: "I remember the times when my entire life revolved around work. Not anymore! Commerce Media Tech gives me flexibility and lets me work from wherever I want in the world. This means I can take my bike for a proper spin along the sunny spanish coastline… right after this interview!",
    author: {
      name: "Beata",
      position: "Product Designer",
    }
  },
  {
    img: sliderImg6,
    imgWidth: 692,
    imgHeight: 733,
    imgSm: sliderImg6sm,
    offset: 76,
    text: "Since I joined Commerce Media Tech I’ve worked as a Compliance Specialist, a Product Manager and now I’m a QA Software Engineer. In each of these roles I felt that my opinion was important & valued. Believe me, it’s not always the case with other companies…",
    author: {
      name: "Krzysztof",
      position: "Software Engineering Expert, Zeropark",
    }
  },
  {
    img: sliderImg1,
    imgWidth: 576,
    imgHeight: 733,
    imgSm: sliderImg1sm,
    offset: 10,
    text: "If you think “growth potential” and “career opportunities” are just empty phrases companies overuse, ask that guy who joined Commerce Media Tech as an Account Manager and became CEO. Me.",
    author: {
      name: "Aleksander",
      position: "CEO",
    },
  },
]

const TestimonialsSlider = (props) => {
  const [slides, setSlides] = useState(props.version === 1 ? testimonialsData1 : testimonialsData2);
  const [activeSlide, setActiveSlide] = useState(0);
  const slidesKeys = Object.keys(slides);

  // swiper
  const [swiper, setSwiper] = useState(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    // <div className={classes.sliderWrapper}>
    <Swiper
      speed={700}
      modules={[Navigation, EffectFade]}
      spaceBetween={0}
      navigation={{
        nextEl: nextRef.current,
        prevEl: prevRef.current,
        disabledClass: classes.swiperNavigationButtonDisabled,
      }}
      slidesPerView="1"
      onInit={(swiper) => {
        swiper.params.navigation.nextEl = nextRef.current;
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.navigation.init();
        swiper.navigation.update();
      }}
      className={classes.swiperContainer}
      effect={"fade"}
      fadeEffect={{
        crossFade: true,
      }}
    >
      {slides.map((slide, index) => {
        return (
          <SwiperSlide key={index} className={classes.swiperSlide}>
            <div className={classes.slideInner}>
                <div className={classes.quoteWrapper}>{slide.text}</div>
                <div className={classes.authorWrapper}>
                  <p>
                    <strong>{slide.author.name}</strong>
                  </p>
                  <p>{slide.author.position}</p>
                </div>
                <div className={classes.imgWrapper} style={{transform: `translateX(${slide.offset}px)`}}>
                  <Image src={slide.img} alt="" placeholder="blur" quality={80} width={slide.imgWidth} height={slide.imgHeight}/>
                </div>
                <div className={classes.imgWrapperSm}>
                  <Image src={slide.imgSm} alt="" placeholder="blur" quality={90}/>
                </div>
            </div>
          </SwiperSlide>
        );
      })}

      <div className={classes.swiperNavigationWrapper}>
        <div ref={prevRef} className={classes.swiperNavigationPrev}></div>
        <div ref={nextRef} className={classes.swiperNavigationNext}></div>
      </div>
    </Swiper>
    // </div>
  );
};

export default TestimonialsSlider;
