import classes from "./TestimonialsSection.module.scss";
import TestimonialsSlider from "./TestimonialsSlider";
import cn from "classnames";

const TestimonialsSection = (props) => {
  const version = props.version;

  return (
    <section
      className={cn(classes.testimonials, {
        [classes.testimonialsTopPadding]: props.version === 1,
      })}>
      <div className={classes.inner}>
        {version === 1 ? (
          <div className={classes.headingWrapper}>
            <h2
              className={classes.heading}
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease">
              What do we love about <span>Commerce Media Tech?</span>
            </h2>
          </div>
        ) : (
          <div className={classes.headingWrapper}>
            <p
              className={classes.headingOverline}
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-easing="ease">
              Our team
            </p>
            <h2
              className={classes.heading}
              data-sal="slide-up"
              data-sal-delay="400"
              data-sal-easing="ease">
              We all have <span>a story to tell</span>
            </h2>
          </div>
        )}
        <div
          className={classes.sliderWrapper}
          data-sal="slide-up"
          data-sal-delay="600"
          data-sal-easing="ease"
          data-sal-duration="800">
          <TestimonialsSlider version={version} />
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
